// Only code specific to this site should be here. Anything that can be used across multiple sites
// should be pushed into the KMS.
import { SideScroller } from "./sidescroller.js";


window.addEventListener("load", function() {
	var ladders = document.querySelectorAll(".ladder-container");
	var scrollEl = document.scrollingElement;
	function moveFireFighter() {        
		// Every 100px change the frame of the firefighter.
		var position = (Math.floor(scrollEl.scrollTop / 200) % 3) + 1;
		ladders.forEach((ladder) => {
			ladder.classList.remove("activate-frame-1", "activate-frame-2", "activate-frame-3");
			ladder.classList.add("activate-frame-" + position);
		});
	}
	window.addEventListener("scroll", moveFireFighter);
	moveFireFighter();
	initialiseSideScrollers();
});

function initialiseSideScrollers() {
	// Find all the elements with class of section-scroller, and create a side scroller instance to manage it
	var sideScrollers = document.querySelectorAll(".section-scroller");
	[...sideScrollers].forEach((sideScroller) => {
		new SideScroller(sideScroller);
	});

}