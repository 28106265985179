export class SideScroller {
	constructor(el) {
		this.el = el;
		this.stage = el.querySelector(".stage");
		console.log("Initialising side scroller on ", el);
		// create UI elements
		this.addUI();
		window.addEventListener("resize", this.checkButtonVisibility.bind(this));
		this.checkButtonVisibility();
	}
	addUI() {
		// create a previous and next button
		this.previousButton = document.createElement("button");
		this.previousButton.setAttribute("aria-label", "Previous");
		this.previousButton.classList.add("previous", "scroller-button");
		this.previousButton.innerHTML = "<i class=\"fas fa-chevron-left\"></i>";
		this.previousButton.addEventListener("click", this.previous.bind(this));
		this.previousButton.addEventListener("keydown", (event) => {
			if (event.code === "Enter" || event.code === "Space") {
				event.preventDefault();
				this.previous();
			}
		});
		this.el.appendChild(this.previousButton);
		this.nextButton = document.createElement("button");
		this.nextButton.setAttribute("aria-label", "Next");
		this.nextButton.classList.add("next", "scroller-button");
		this.nextButton.innerHTML = "<i class=\"fas fa-chevron-right\"></i>";
		this.nextButton.addEventListener("click", this.next.bind(this));
		this.nextButton.addEventListener("keydown", (event) => {
			if (event.code === "Enter" || event.code === "Space") {
				event.preventDefault();
				this.next();
			}
		});
		this.el.appendChild(this.nextButton);

	}
	checkButtonVisibility() {
		// Check if the scroller has content wider than itself
		const hasScroll = (this.stage.scrollWidth > this.stage.clientWidth);
		// The previous button should show if we are scrolled to the right at all.
		this.togglePreviousButton(hasScroll && (this.stage.scrollLeft > 0));
		// The next button should show if we are not scrolled to the end of the content.
		// We are at the end if the scroll left + the scroll width is the same or greater then the total scrolling space (clientwidth)
		// We add 5% to the client width to allow for rounding errors.
		this.toggleNextButton(hasScroll && (this.stage.scrollLeft < this.stage.scrollWidth - (this.stage.clientWidth*1.05)));            
	}
	toggleNextButton(visible) {
		this.nextButton.style.display = (visible) ? "block" : "none";
	}
	togglePreviousButton(visible) {
		this.previousButton.style.display = (visible) ? "block" : "none";
	}
	itemSize() {
		// Get the width of the first element in the stage including margins
		// We assume all items are of equal width.
		var item = this.stage.children[0];
		var style = window.getComputedStyle(item);
		var margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
		return item.offsetWidth + margin;		
	}
	previous(){
		console.log("Previous");
		smoothScroll(this.stage, -this.itemSize(), 500, this.checkButtonVisibility.bind(this));
	}
	next(){
		console.log("Next");
		// scroll the stage by the width of the first child and then fire a callback to check if the buttons should be visible
		smoothScroll(this.stage, this.itemSize(), 500, this.checkButtonVisibility.bind(this));
	}
}

function smoothScroll(element, distance, duration, callback) {
	// Scrolls an element horizontally and then fires a callback.
	const startPosition = element.scrollLeft;
	let startTime = null;
  
	function animation(currentTime) {
		if (startTime === null) startTime = currentTime;
		const timeElapsed = currentTime - startTime;
		const frame_position = ease(Math.min(timeElapsed, duration), startPosition, distance, duration);
		element.scrollLeft = frame_position;
		if (timeElapsed < duration) requestAnimationFrame(animation);
		else if (callback) callback();
	}
  
	function ease(t, b, c, d) {
		t /= d / 2;
		if (t < 1) return (c / 2) * t * t + b;
		t--;
		return (-c / 2) * (t * (t - 2) - 1) + b;
	}
  
	requestAnimationFrame(animation);
}
  